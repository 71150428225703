//Function aims at whether encrypting or decrypting the password
//Setup props: label: label name,
//             num: how many password form in the current page
//             name: form value name
//             value: form value
//             onChange: onChange event
import {useEffect, useId, useState} from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {BsFillEyeFill, BsFillEyeSlashFill} from "react-icons/bs";

export function VisPassword(props) {
    const [visible, setVisible] = useState(false);
    const [inputType, setInputType] = useState("password");

    const passwordHintId = useId();
    useEffect(() => {
        if (visible) {
            // document.getElementById(`pswInvis-${props.num}`).style.display = "inline";
            // document.getElementById(`pswVis-${props.num}`).style.display = "none";
            document.getElementById(`pswInvis-${passwordHintId}`).style.display = "inline";
            document.getElementById(`pswVis-${passwordHintId}`).style.display = "none";
            setInputType("text");

        } else {
            // document.getElementById(`pswInvis-${props.num}`).style.display = "none";
            // document.getElementById(`pswVis-${props.num}`).style.display = "inline";
            document.getElementById(`pswInvis-${passwordHintId}`).style.display = "none";
            document.getElementById(`pswVis-${passwordHintId}`).style.display = "inline";
            setInputType("password")
        }

    });
    return (
        <>
            <FloatingLabel label={props.label}>
                <Form.Control
                    type={inputType}
                    name={props.name}
                    value={props.value}
                    onChange={props.onChange}
                    placeholder="Please enter your password"/>
            </FloatingLabel>
            <InputGroup.Text>
                {/*<BsFillEyeFill className="pswVis" id={`pswVis-${props.num}`} onClick={() => setVisible(!visible)}/>*/}
                {/*<BsFillEyeSlashFill className="pswVis invis" id={`pswInvis-${props.num}`}*/}
                {/*                    onClick={() => setVisible(!visible)}/>*/}
                <BsFillEyeFill className="pswVis" id={`pswVis-${passwordHintId}`} onClick={() => setVisible(!visible)}/>
                <BsFillEyeSlashFill className="pswVis invis" id={`pswInvis-${passwordHintId}`}
                                    onClick={() => setVisible(!visible)}/>
            </InputGroup.Text>
        </>
    )
}
