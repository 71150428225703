import '../../css/styles.css';
import {useState, useEffect} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from "react-bootstrap/Stack";
import {AiOutlineUser, AiOutlineKey} from 'react-icons/ai';
import {useCookies} from "react-cookie";
import {NavigationBar} from "../../js/navbar";
import {RootUrl, ApiUrl} from "../../js/url";
import {VisPassword} from "../../js/password";
import axios from "axios";
import data from "bootstrap/js/src/dom/data";
import {useLoaderData, useLocation} from "react-router-dom";


export function Login() {
    // eslint-disable-next-line no-restricted-globals
    // console.log(history.location);
    return (
        <>
            {/*<NavigationBar />*/}
            {/*<div className="mainContent">*/}
                <LoginForm/>
            {/*</div>*/}

        </>
    )
}


function LoginForm() {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [cookies, setCookies] = useCookies();

    var params = {}

    useEffect(()=>{
        params = formData;
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // // Do something with the form data, for example, make an API request
        axios.post(RootUrl + "/login", params,
            {
                headers:{
                    "Content-Type":"application/json",
                    "Access-Control-Allow-Origin": "*"
                },
            }).then(response => {
                const res = response.data
            if(res.msg=="Welcome back"){
                // eslint-disable-next-line react-hooks/rules-of-hooks,no-restricted-globals
                setCookies("user", res.data.username);
                setCookies("id", res.data.id);
                setCookies("role", res.data.role);


                // eslint-disable-next-line no-restricted-globals
                history.go(-1);
                // history.back();
            }
        }).catch(errors => {
            console.log(errors);
        });

    };

    return (
        <Container className="login" onSubmit={handleSubmit}>
            <Col xs={12} lg={6}>
                <Stack gap={3}>
                    <div className="login-header">
                        <h1>Welcome Back</h1>
                    </div>
                    <div className="login-form">
                        <Form method="post" action="/login">
                            <Form.Group className="mb-3" controlId="loginEmail">
                                <InputGroup>
                                    <InputGroup.Text>
                                        <AiOutlineUser/>
                                    </InputGroup.Text>
                                    <FloatingLabel label="Email or Username">
                                        <Form.Control
                                            name="email"
                                            type="email"
                                            value={formData.email}
                                            placeholder="example@mail.com"
                                            onChange={handleInputChange}
                                        />
                                    </FloatingLabel>
                                </InputGroup>
                                <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="md-3">
                                <InputGroup>
                                    <InputGroup.Text>
                                        <AiOutlineKey/>
                                    </InputGroup.Text>
                                    <VisPassword
                                        name="password"
                                        label="Password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <a id="login-form_forgot-password" href="/password-reset/forget-password">Forgot Password?</a>
                            <div className="d-grid gap-2">
                                <Button size="lg" variant="primary" type="submit">
                                    Sign In
                                </Button>
                            </div>
                        </Form>
                    </div>
                    <div className="login-footer">
                        <Stack gap={3}>
                            <div className="login-divider">or</div>
                            {/*<a className="link-button" href="/register">*/}
                            <div className="d-grid gap-2">
                                <Button className="link-button" href="/register" size="lg" variant="outline-dark">
                                    New Customer? Join Now
                                </Button>
                            </div>
                            {/*</a>*/}
                        </Stack>
                    </div>
                </Stack>
            </Col>
        </Container>
    )
}






