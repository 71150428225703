import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/styles.css";
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import axios from "axios";
import {RootUrl, ApiUrl} from "../../js/url";
import {useCallback, useEffect, useState} from "react";
import {Toast} from "react-bootstrap";

// export function CategoriesIndex() {
//     return (
//         <>
//             <div className="indexHeader">
//                 <Button variant="secondary">Add New</Button>
//             </div>
//             <CategoriesTable/>
//         </>
//     )
//
// }

export function CategoriesTable() {
    const [categories, setCategories] = useState([]);
    const getCategory = useCallback(async () => {
        const result = await axios.get(ApiUrl + "/categories",
            {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(response=>{
                return response;
        });
        const data = result.data
        setCategories(data.categories);
    }, []);
    useEffect(() => {
        getCategory();
    }, []);

    // setCategories(res.data.categories);
    // const categories = res.data.categories;
    return (
        <Table
            striped
            bordered
            hover
            // responsive="xs"
            className="indexTable"
        >
            <thead>
            <tr>
                <th className="table-num">Num</th>
                <th>Name</th>
                <th className="table-operation">Operation</th>
            </tr>
            </thead>
            <tbody>
            {categories.map((category, index) => (
                <tr>
                    <td className="table-num">{index + 1}</td>
                    <td>{category.name}</td>
                    <td className="table-operation">
                        <Button variant="link">Edit</Button>
                        <Button variant="link">Delete</Button>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
}

function AddCategory() {
    return (
        <Toast></Toast>
    );
}

