import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/styles.css";
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import {ApiUrl} from "../../js/url";
import {useEffect, useState} from "react";
import {useLoaderData} from "react-router-dom";


export function ConfigTable() {

    const info = useLoaderData();
    // const [config, setConfig] = useState([]);
    const [show, setShow] = useState({
        show: false,
        id: ""
    });
    // const getConfig = useCallback(async () => {
    //     const result = await axios.get(ApiUrl + "/configs",
    //         {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Access-Control-Allow-Origin": "*"
    //             }
    //         }).then(response => {
    //         return response;
    //     });
    //     const data = result.data
    //     setConfig(data.configs);
    // }, []);
    // useEffect(() => {
    //     getConfig();
    // }, []);

    const handleOnShow = (e) => {
        if (e) {
            setShow({
                show: !show.show,
                id: e.target.id
            });
        } else setShow({
            show: !show.show,
            id: ""
        })

    }

    return (
        <>
            <Modal size="lg" centered show={show.show}>
                <ManageConfig id={show.id} onHide={handleOnShow}/>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleOnShow}>Close</Button>
                    <Button variant="primary">Save</Button>
                </Modal.Footer>
            </Modal>

            <Table striped bordered hover className="indexTable">
                <thead>
                <tr>
                    <th>Num</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Operation</th>
                </tr>
                </thead>
                <tbody>
                {info.config.length ? (
                    <>
                        {info.config.map((item, index) => (
                            <tr>
                                <td className="table-num">{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.description}</td>
                                <td className="table-operation">
                                    <Button variant="link" id={item.id} onClick={handleOnShow}>Edit</Button>
                                </td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <></>
                )}
                </tbody>
            </Table>
        </>
    );
}

export function ManageConfig(props) {
    const [formData, setFormData] = useState({
        name: "",
        url: "",
        description: ""
    });
    var params = formData;

    useEffect(() => {
        params = formData;
    })

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
        if (e.target.type === "file") {
            const [file] = e.target.files;
            let fileUrl = "";
            if (file) {
                fileUrl = URL.createObjectURL(file);
                document.getElementById("uploadImage").style.backgroundImage = "url(" + fileUrl + ")";
                document.getElementById("uploadImageLabel").style.opacity = "0";
            }
            console.log(fileUrl);
            setFormData({...formData, [name]: e.target.files[0]});
        }
    };

    const handleConfigSubmit = (e) => {
        e.preventDefault();
        document.getElementById("modal-backdrop").hidden = false;
        console.log(e);
        if (params.url === "") {
            params.url = "no need picture";
        }

        axios.post(ApiUrl + "/config/add", params,
            {
                headers: {
                    "Content-Type": "multipart/form-data;boundary=abcde12345",
                    "Access-Control-Allow-Origin": "*"
                },
            }).then(response => {
                document.getElementById("modal-backdrop").hidden = true;
                const res = response.data;
                if (response.status === 200) {
                    document.getElementById("close-modal").click();
                    alert("Add Config Successfully!");

                    // eslint-disable-next-line no-restricted-globals
                    history.go();
            }}).catch(errors => {
                document.getElementById("modal-backdrop").hidden = true;
                alert(errors.msg);
            // console.log(errors);
        });
        console.log(params);
    }

    return (
        <>
            {/*<Modal.Header closeButton onHide={props.onHide}>*/}
            {/*    <Modal.Title>*/}
            {/*        Add Config*/}
            {/*    </Modal.Title>*/}
            {/*</Modal.Header>*/}
            {/*<Modal.Body>*/}
            <Form method="post" action="/addConfig" onSubmit={handleConfigSubmit}>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>Name</Form.Label>
                    <Col sm={10}>
                        <Form.Control name="name" type="text" onChange={handleInputChange}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>Image</Form.Label>
                    <Col sm={10}>
                        <div id="uploadImage" className="uploadImage">
                            <Form.Label id="uploadImageLabel">Choose an Image</Form.Label>
                            <Form.Control name="url" accept="image/*" type="file" onChange={handleInputChange}/>
                        </div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>Description</Form.Label>
                    <Col sm={10}>
                        <Form.Control name="overview" as="textarea" rows={3} onChange={handleInputChange}/>
                    </Col>
                </Form.Group>
                <Button id="submit" type="submit" hidden></Button>
            </Form>
            {/*</Modal.Body>*/}
        </>
    )

}

// export function AddConfig() {
//
//     return (
//         <Form method="post" action="/addConfig" onSubmit={handleConfigSubmit}>
//             <Form.Group as={Row} className="mb-3">
//                 <Form.Label column sm={2}>Name</Form.Label>
//                 <Col sm={10}>
//                     <Form.Control name="name" type="text" onChange={handleInputChange}/>
//                 </Col>
//             </Form.Group>
//             <Form.Group as={Row} className="mb-3">
//                 <Form.Label column sm={2}>Image</Form.Label>
//                 <Col sm={10}>
//                     <div id="uploadImage" className="uploadImage">
//                         <Form.Label id="uploadImageLabel">Choose an Image</Form.Label>
//                         <Form.Control name="url" accept="image/*" type="file" onChange={handleInputChange}/>
//                     </div>
//                 </Col>
//             </Form.Group>
//             <Form.Group as={Row} className="mb-3">
//                 <Form.Label column sm={2}>Description</Form.Label>
//                 <Col sm={10}>
//                     <Form.Control name="overview" as="textarea" rows={3} onChange={handleInputChange}/>
//                 </Col>
//             </Form.Group>
//             <Button id="submit" type="submit" hidden></Button>
//         </Form>
//     )
// }
//
