import axios from "axios";
import {RootUrl, ApiUrl} from "../js/url";

export async function  getConfigs(){
    const configs = await axios.get(ApiUrl + "/config",
        {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
        return response;
    });
    return configs;
}

export async function getCategories(){
    const categories = await axios.get(ApiUrl + "/categories",
        {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
        return response;
    });
    return categories;
}

export async function getThemes(){
    const themes = await axios.get(ApiUrl + "/themes",
        {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
        return response;
    });
    return themes;
}

export async function getThemesByCategory(category_id){
    const themes = await axios.get(ApiUrl + "/themes/category_id=" + category_id,
        {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
        return response;
    });
    return themes;
}

export async function getReleasedProgramme(theme_id){
    const programme = await axios.get(ApiUrl + "/programmes/released/theme_id=" + theme_id,
        {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            return response;
        });
    return programme;
}