import {getCategories, getConfigs, getReleasedProgramme, getThemes, getThemesByCategory} from "./api";


export async function rootLoader() {
    //load config items and category list
    const result_con = await getConfigs();
    const result_cate = await getCategories();
    const data_con = result_con.data.config;
    const categories = result_cate.data.categories;
    let config = {};
    if (data_con.length !== 0) {
        data_con.map((item) => {
            config[item.name] = {url: item.url, desription:item.desription};
        });
    }

    return {config, categories};
}

export async function configLoader() {
    const result = await getConfigs();
    const config = result.data.config;
    return {config};
}

export async function categoryLoader() {
    const result = await getCategories();
    const categories = result.data.categories;
    return {categories};
}

export async function themesLoader() {
    const result = await getThemes();
    const themes = result.data.themes;
    return {themes};
}

export async function themesByCategoryLoader({params}) {
    // const {categorySlug} = useParams();
    // const [category_id, category_name] = categorySlug.split("&");
    if (params.category_id !== "all-careers") {
        const themes = await getThemesByCategory(params.category_id);
        return {
            "category_name": params.category_name,
            "themes": themes.data.themes
        }
    }
    const themes = await getThemes();
    return {
        "category_name": "Our Subjects",
        "themes": themes.data.themes
    }

}

export async function programmeLoader({params}) {
    const result = await getReleasedProgramme(params.theme_id);
    const programme = result.data.data;
    return {programme};
}

