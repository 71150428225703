import "bootstrap/dist/css/bootstrap.min.css";
import "../css/styles.css";
import {useState, useCallback, useEffect} from "react";
import {useLoaderData} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Col from "react-bootstrap/Col";
import {BsFillCartFill} from "react-icons/bs";
import {BiUserCircle} from "react-icons/bi";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import {useCookies} from "react-cookie";
import axios from "axios";
import {RootUrl, ApiUrl} from "./url";
import {getCategories} from "./api";

// const user = document.cookie.split("; ")
//     .find((row) => row.startsWith("user="))
//     ?.split("=")[1].replace("%20", " ");
// const role = document.cookie.split("; ")
//     .find((row) => row.startsWith("role="))
//     ?.split("=")[1];


export function ControlledCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (

        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                {/*<ExampleCarouselImage text="First slide" />*/}
                <Image className="carouselImage" src="carousels/6a165622ae.jpg"/>
                <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                {/*<ExampleCarouselImage text="Second slide" />*/}
                <Image className="carouselImage" src="carousels/038ff1971b.jpg"/>
                <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                {/*<ExampleCarouselImage text="Third slide" />*/}
                <Image className="carouselImage" src="carousels/670ba3b582.jpg"/>
                <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}


export function IndexCards() {
    return (
        <>
            <Col>
                {/*<Card style={{width: '18rem'}}>*/}
                <Card>
                    <Card.Img className="indexCardImg" variant="top" src="cards/48b547493d.jpg"/>
                    <Card.Body>
                        <Card.Title>Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the
                            bulk of the card's content.
                        </Card.Text>
                        <Button variant="primary">Go somewhere</Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                {/*<Card style={{width: '18rem'}}>*/}
                <Card>
                    <Card.Img className="indexCardImg" variant="top" src="cards/c2bde0266b.jpg"/>
                    <Card.Body>
                        <Card.Title>Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the
                            bulk of the card's content.
                        </Card.Text>
                        <Button variant="primary">Go somewhere</Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                {/*<Card style={{width: '18rem'}}>*/}
                <Card>
                    <Card.Img className="indexCardImg" variant="top" src="cards/cb9c5be020.jpg"/>
                    <Card.Body>
                        <Card.Title>Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the
                            bulk of the card's content.
                        </Card.Text>
                        <Button variant="primary">Go somewhere</Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                {/*<Card style={{width: '18rem'}}>*/}
                <Card>
                    <Card.Img className="indexCardImg" variant="top" src="cards/e7ed24a519.jpg"/>
                    <Card.Body>
                        <Card.Title>Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the
                            bulk of the card's content.
                        </Card.Text>
                        <Button variant="primary">Go somewhere</Button>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
}


export function NavigationBar(props) {
    const info = useLoaderData();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <header id="header">
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container fluid>
                    <Navbar.Brand href="/"><Image
                        src={`${info.config.logo ? info.config.logo.url : "/"}`}
                        width={80} height={80}/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav" onMouseOver={handleShow} >
                        <Nav className="me-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <ProgrammesNav/>
                            <NavDropdown title="Events" id="ev-nav-dropdown">
                                <NavDropdown.Item href="/action/3.1"></NavDropdown.Item>
                                <NavDropdown.Item href="/action/3.2">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item href="/action/3.4">
                                    Event Management
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Help" id="help-nav-dropdown">
                                <NavDropdown.Item href="/action/3.1">About Us</NavDropdown.Item>
                                <NavDropdown.Item href="/action/3.2">
                                    Contact Us
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item href="/action/3.4">
                                    Download Instruction
                                </NavDropdown.Item>
                            </NavDropdown>
                            <ConfigNav role={props.role}/>
                        </Nav>
                        <Nav>
                            <LoginUser user={props.user}/>
                            <Nav.Link className="clickIcon" href="/cart">
                                <BsFillCartFill/>
                            </Nav.Link>
                            <Nav.Link>
                                <Language/>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {/*<OffCanvasExample show={show} placement="top"/>*/}
        </header>
    );
}



function Language() {
    const [language, setLanguage] = useState("Chinese");

    function changeLanguage() {
        setLanguage("Chinese");
        if (language === "Chinese") {
            setLanguage("English");
        }
    }

    return (
        <a onClick={changeLanguage} name={language}>{language}</a>
    )
}

function ProgrammesNav() {
    //getCategories
    const {categories} = useLoaderData();
    // const [categories, setCategories] = useState([]);
    //
    // const initCategory = useCallback(async () => {
    //     const result = await getCategories();
    //     const data = result.data;
    //     setCategories(data.categories);
    // }, []);
    //
    // useEffect(()=>{
    //     initCategory();
    // },[]);

    return (
        <NavDropdown title="Programmes" id="pg-nav-dropdown">
            <NavDropdown.Header>Browser by Career</NavDropdown.Header>
            <NavDropdown.Item href="/programmes/all-careers">All Careers</NavDropdown.Item>
            {categories.length ? (
                <>
                    {categories.map((item)=>(
                        <NavDropdown.Item href={`/programmes/${item.id}/${item.name}`}>{item.name}</NavDropdown.Item>
                        // <NavDropdown.Item href={`/programmes/${item.id}`}>{item.name}</NavDropdown.Item>
                    ))}
                </>
            ) : (
                <NavDropdown.Item>No Categories yet</NavDropdown.Item>
            )}

        </NavDropdown>

    )
}

function ConfigNav(props) {
    if (props.role !== "admin") {
        return (<></>);
    }
    return (
        <>
            <NavDropdown title="Config" id="help-nav-dropdown">
                <NavDropdown.Item href="/config/basic-config">Basic Config</NavDropdown.Item>
                <NavDropdown.Header>Account</NavDropdown.Header>
                <NavDropdown.Item href="/config/role">Role</NavDropdown.Item>
                <NavDropdown.Item href="/config/user">User</NavDropdown.Item>
                <NavDropdown.Header>Programme</NavDropdown.Header>
                <NavDropdown.Item href="/config/categories">Category</NavDropdown.Item>
                {/*<NavDropdown.Item href="/config/theme-index">Theme</NavDropdown.Item>*/}
                <NavDropdown.Item href="/config/programmes">Programme</NavDropdown.Item>
            </NavDropdown>

        </>

    );

}

function OffCanvasExample({name, ...props}) {
    // const [show, setShow] = useState(false);
    //
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);


    return (
        <>
            {/*<Offcanvas show={props.show} onHide={props.show} {...props}>*/}
            <Offcanvas {...props}>
            <Offcanvas.Header>
                    <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    Some text as placeholder. In real life you can have the elements you
                    have chosen. Like, text, images, lists, etc.
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

function LoginUser(props) {
    const [cookies, setCookies, removeCookies] = useCookies();

    function logout() {
        axios.get(RootUrl + "/logout").then(response => {
            if (response.status === 200) {
                // setCookies("user", "");
                removeCookies("role", {path: "/"});
                removeCookies("user", {path: "/"});

                // eslint-disable-next-line no-restricted-globals
                // history.back();
                document.location.reload();
            }
        }).catch(errors => {
            console.log(errors);
        });
    }

    if (props.user !== "" && props.user !== undefined) {
        return (
            <NavDropdown className="clickIcon" drop="start" title={<BiUserCircle/>}>
                <NavDropdown.Header>{`Hello ${props.user}`}</NavDropdown.Header>
                <NavDropdown.Item href='/users/user_id'>Profile</NavDropdown.Item>
                <NavDropdown.Item href='/users/orders'>Orders</NavDropdown.Item>
                <NavDropdown.Item href='/users/favourite'>Favourite</NavDropdown.Item>
                <NavDropdown.Divider/>
                <NavDropdown.Item onClick={logout}>Log out</NavDropdown.Item>
            </NavDropdown>
        );
    }
    return (
        <Nav.Link className="clickIcon" href="/login">
            <BiUserCircle/>
        </Nav.Link>
    )


}


// export const RootUrl = "http://127.0.0.1:5000";
// export const ApiUrl = "http://127.0.0.1:5000/api/v1";





