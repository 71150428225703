import "bootstrap/dist/css/bootstrap.min.css";
import "../css/styles.css";
import {useLoaderData} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";


export function Footer(){
    const info = useLoaderData();
    return (
        <div className="footer">
            <Row>
                <Col>
                    {/*<Row className="justify-content-md-center">*/}
                        <Image
                            src={`${info.config.logo ? info.config.logo.url : "/"}`}
                            width={240}
                            height={240}
                            style={{marginLeft:"2rem"}}
                        />
                    {/*</Row>*/}
                </Col>
                <Col>
                    <h4 className="footer-nav-title">About Us</h4>
                </Col>
                <Col>
                    <h4 className="footer-nav-title">Contact Us</h4>
                </Col>
                <Col>
                    <h4 className="footer-nav-title">Privacy Policy</h4>
                </Col>
            </Row>
            <Row></Row>
        </div>
    );
}