import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/styles.css";
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import {BsChevronCompactDown} from "react-icons/bs";
import axios from "axios";
import {ApiUrl} from "../../js/url";
import {getCategories} from "../../js/api";
import {useCallback, useEffect, useState} from "react";
import {useLoaderData} from "react-router-dom";


// export function ProgrammeIndex() {
//     return (
//         <>
//             <div className="indexHeader">
//                 <Button variant="secondary">Add New</Button>
//             </div>
//             <ProgrammeTable/>
//         </>
//     )
//
// }
// export async function themesLoader(){
//     const result = await getThemes();
//     const themes = result.data.themes;
//     return themes;
// }

export function ProgrammeTable() {

    const info = useLoaderData();
    // const [themes, setThemes] = useState([]);
    const [addProgramme, setAddProgramme] = useState({
        show: false,
        theme_id: ""
    });
    // const initThemes = useCallback(async () => {
    //     const result = await getThemes();
    //     const data = result.data;
    //     setThemes(data.themes);
    // }, []);
    // useEffect(() => {
    //     initThemes();
    // }, []);

    const handleOnShow = (e) => {
        if (e) {
            setAddProgramme({
                show: !addProgramme.show,
                theme_id: e.target.id
            });
        } else setAddProgramme({
            show: !addProgramme.show,
            theme_id: ""
        })

    }

    const handleOnSubmit = () => {
        document.getElementById("submit").click();
    }

    const handleOnRealse = (e) => {
        axios.post(ApiUrl + "/programmes/release", e.target.id,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                },
            }).then(response => {
            const res = response.data
            if (response.status === 200) {
                alert(res.msg);

                // eslint-disable-next-line no-restricted-globals
                history.go();
            }
        }).catch(errors => {
            alert(errors.msg);
            // console.log(errors);
        });
    }

    return (
        <>
            <Modal size="lg" centered show={addProgramme.show}>
                <AddProgramme theme_id={addProgramme.theme_id} onHide={handleOnShow}/>
                <Modal.Footer>
                    <Button id="close-modal" variant="secondary" onClick={handleOnShow}>Close</Button>
                    <Button variant="primary" onClick={handleOnSubmit}>Save</Button>
                </Modal.Footer>
                <div id="modal-backdrop" className="fade modal-backdrop show" hidden>
                    <Spinner animation="border" variant="primary"/>
                </div>
            </Modal>

            <Table
                striped
                bordered
                hover
                // responsive="xs"
                className="indexTable"
            >
                <thead>
                <tr>
                    <th>Num</th>
                    <th>Category</th>
                    <th>Name</th>
                    <th>Dates</th>
                    <th>Location</th>
                    <th>Status</th>
                    <th>Age Range</th>
                    <th>Expire Time</th>
                    <th>Poster</th>
                    <th>Operation</th>
                </tr>
                </thead>
                <tbody>
                {info.themes.length ? (
                    <>
                        {info.themes.map((theme, index) => (
                            <>
                                <tr>
                                    <td className="parentRow-index table-num">
                                        {theme.programmes.length ? (
                                            <BsChevronCompactDown/>
                                        ) : (index + 1)}
                                    </td>
                                    <td>{theme.category_name}</td>
                                    <td>{theme.name}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        {(theme.age_range === 0) ? (
                                            <>All Ages</>
                                        ) : (
                                            <>
                                                {(theme.age_range === 1) ? (
                                                    <>12-14</>
                                                ) : (
                                                    <>
                                                        {(theme.age_range === 2) ? (
                                                            <>15-18</>
                                                        ) : (
                                                            <>Adult</>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td className="table-operation">
                                        <Button variant="link">Edit</Button>
                                        <Button variant="link">Delete</Button>
                                        <Button variant="link" id={theme.id} onClick={handleOnShow}>Add
                                            Programme</Button>
                                    </td>
                                </tr>
                                {theme.programmes.length ? (
                                    <>
                                        {theme.programmes.map((programme, idx) => (
                                            <tr className="programmeRow">
                                                <td>{idx + 1}</td>
                                                <td></td>
                                                <td>{programme.name}</td>
                                                <td>{programme.dates}</td>
                                                <td>{programme.location}</td>
                                                <td>
                                                    {(programme.status === 0) ? (
                                                        <>Expired</>
                                                    ) : (
                                                        <>Active</>
                                                    )}
                                                </td>
                                                <td></td>
                                                <td>{programme.expire_date}</td>
                                                <td>{programme.operator_name}</td>
                                                <td className="table-operation">
                                                    <Button variant="link">Edit</Button>
                                                    <Button variant="link">Delete</Button>
                                                    {(programme.status === 0) ? (
                                                        <Button variant="link" id={programme.id}
                                                                onClick={handleOnRealse}>Release</Button>
                                                    ) : (
                                                        <Button variant="link" id={programme.id}>Repeal</Button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>

                        ))}
                    </>
                ) : (
                    <></>
                )}

                </tbody>
            </Table>
        </>
    );
}

export function AddProgramme(props) {
    const [formData, setFormData] = useState({
        theme_id: props.theme_id,
        name: "",
        bg_image: "",
        pic1: "",
        pic2: "",
        video: "",
        dates: "",
        length: "",
        location: "",
        accommodation: "",
        price: "",
        overview: "",
        description: "",
        timetable: ""
    });
    var params = formData;

    useEffect(() => {
        params = formData;
    })

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
        if (e.target.type === "file") {
            const [file] = e.target.files;
            let fileUrl = "";
            if (file.type.split("/")[0] === "image") {
                fileUrl = URL.createObjectURL(file);
                e.target.parentElement.style.background = "url(" + fileUrl + ") no-repeat center";
                e.target.parentElement.style.backgroundSize = "cover";
                e.target.previousSibling.style.opacity = "0";
                // document.getElementById("uploadImage").style.backgroundImage = "url(" + fileUrl + ")";
                // document.getElementById("uploadImageLabel").style.opacity = "0";
            }
            console.log(fileUrl);
            setFormData({...formData, [name]: e.target.files[0]});
        }
    };

    const handleProgrammeSubmit = (e) => {
        e.preventDefault();
        document.getElementById("modal-backdrop").hidden = false;
        console.log(e);

        axios.post(ApiUrl + "/programmes/add", params,
            {
                headers: {
                    // "Content-Type": "application/json",
                    "Content-Type": "multipart/form-data;boundary=abcde12345",
                    "Access-Control-Allow-Origin": "*"
                },
            }).then(response => {
            document.getElementById("modal-backdrop").hidden = true;
            const res = response.data
            // if (res.msg === "Success") {
            if (response.status===200) {
                document.getElementById("close-modal").click();
                alert("Add Programme Successfully!");

                // eslint-disable-next-line no-restricted-globals
                history.go();
            }
        }).catch(errors => {
            document.getElementById("modal-backdrop").hidden = true;
            alert(errors.msg);
            // console.log(errors);
        });
        console.log(params);
    }


    return (
        <>
            <Modal.Header closeButton onHide={props.onHide}>
                <Modal.Title>
                    Add Programme
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form method="post" action="/addProgramme" onSubmit={handleProgrammeSubmit}>
                    {/*<Form.Control id="theme_id" name="theme_id" type/>*/}
                    <Row className="mb-3">
                        <Form.Group as={Col} className="mb-3">
                            <Row>
                                <Form.Label column sm={4}>Name</Form.Label>
                                <Col sm={8}>
                                    <Form.Control name="name" type="text" onChange={handleInputChange}/>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                            <Row>
                                <Form.Label column sm={2}>Price</Form.Label>
                                <Col sm={10}>
                                    <Form.Control name="price" type="text" onChange={handleInputChange}/>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>Background Image</Form.Label>
                        <Col sm={10}>
                            <div className="uploadImage">
                                <Form.Label id="uploadBackgroundImageLabel">Choose a Background Image</Form.Label>
                                <Form.Control name="bg_image" accept="image/*" type="file"
                                              onChange={handleInputChange}/>
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>Picture</Form.Label>
                        <Col sm={5}>
                            <div className="uploadImage">
                                <Form.Label id="uploadImageLabel1">Choose an Image 1</Form.Label>
                                <Form.Control name="pic1" accept="image/*" type="file" onChange={handleInputChange}/>
                            </div>
                        </Col>
                        <Col sm={5}>
                            <div className="uploadImage">
                                <Form.Label id="uploadImageLabel2">Choose an Image 2</Form.Label>
                                <Form.Control name="pic2" accept="image/*" type="file" onChange={handleInputChange}/>
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>Video</Form.Label>
                        <Col sm={10}>
                            <Form.Control name="video" accept="video/*" type="file" onChange={handleInputChange}/>
                        </Col>
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} className="mb-3">
                            <Row>
                                <Form.Label column sm={4}>Date</Form.Label>
                                <Col sm={8}>
                                    <Form.Control name="dates" type="text" onChange={handleInputChange}/>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                            <Row>
                                <Form.Label column sm={2}>Length</Form.Label>
                                <Col sm={10}>
                                    <Form.Control name="length" type="text" onChange={handleInputChange}/>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} className="mb-3">
                            <Row>
                                <Form.Label column sm={4}>Accommodation</Form.Label>
                                <Col sm={8}>
                                    <Form.Control name="accommodation" type="text" onChange={handleInputChange}/>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3">
                            <Row>
                                <Form.Label column sm={2}>Location</Form.Label>
                                <Col sm={10}>
                                    <Form.Control name="location" onChange={handleInputChange}/>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>Overiew</Form.Label>
                        <Col sm={10}>
                            <Form.Control name="overview" as="textarea" rows={3} onChange={handleInputChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>Description</Form.Label>
                        <Col sm={10}>
                            <Form.Control name="description" as="textarea" rows={3} onChange={handleInputChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>Timetable</Form.Label>
                        <Col sm={10}>
                            <Form.Control name="timetable" as="textarea" rows={3} onChange={handleInputChange}/>
                        </Col>
                    </Form.Group>
                    <Button id="submit" type="submit" hidden></Button>
                </Form>
            </Modal.Body>
        </>
    )

}

export function AddTheme() {
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        thumbnail: "",
        name: "",
        category_id: "",
        age_range: "0",
        overview: ""
    });
    var params = formData;

    const initCategory = useCallback(async () => {
        const result = await getCategories();
        const data = result.data
        setCategories(data.categories);
        setFormData({...formData, category_id: data.categories[0].id})
    }, []);


    useEffect(() => {
        initCategory();
        // params = formData;
    }, []);

    useEffect(() => {
        params = formData;
    })

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
        if (e.target.type === "file") {
            const [file] = e.target.files;
            let fileUrl = "";
            if (file) {
                fileUrl = URL.createObjectURL(file);
                document.getElementById("uploadImage").style.backgroundImage = "url(" + fileUrl + ")";
                document.getElementById("uploadImageLabel").style.opacity = "0";
            }
            console.log(fileUrl);
            setFormData({...formData, [name]: e.target.files[0]});
        }
    };

    const handleThemeSubmit = (e) => {
        e.preventDefault();
        document.getElementById("modal-backdrop").hidden = false;
        console.log(e);

        axios.post(ApiUrl + "/themes/add", params,
            {
                headers: {
                    // "Content-Type": "application/json",
                    "Content-Type": "multipart/form-data;boundary=abcde12345",
                    "Access-Control-Allow-Origin": "*"
                },
            }).then(response => {
            document.getElementById("modal-backdrop").hidden = true;
            const res = response.data
            if (res.msg === "Success") {
                document.getElementById("close-modal").click();
                alert("Add Theme Successfully!");

                // eslint-disable-next-line no-restricted-globals
                history.go();
            }
        }).catch(errors => {
            document.getElementById("modal-backdrop").hidden = true;
            alert(errors.msg);
            // console.log(errors);
        });
        console.log(params);
    }


    return (
        <Form method="post" action="/addTheme" onSubmit={handleThemeSubmit}>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>Thumbnail</Form.Label>
                <Col sm={10}>
                    <div id="uploadImage" className="uploadImage">
                        <Form.Label id="uploadImageLabel">Choose an Image</Form.Label>
                        <Form.Control name="thumbnail" accept="image/*" type="file" onChange={handleInputChange}/>
                    </div>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>Name</Form.Label>
                <Col sm={10}>
                    <Form.Control name="name" type="text" onChange={handleInputChange}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>Category</Form.Label>
                <Col sm={10}>
                    <Form.Select name="category_id" onChange={handleInputChange}>
                        {categories.map((category) => (
                            <option value={category.id}>{category.name}</option>
                        ))}
                    </Form.Select>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>Age Range</Form.Label>
                <Col sm={10}>
                    <Form.Select name="age_range" onChange={handleInputChange}>
                        <option value="0" selected>All Ages</option>
                        <option value="1">12-14</option>
                        <option value="2">15-18</option>
                        <option value="3">Adult</option>
                    </Form.Select>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>Overview</Form.Label>
                <Col sm={10}>
                    <Form.Control name="overview" as="textarea" rows={3} onChange={handleInputChange}/>
                </Col>
            </Form.Group>
            <Button id="submit" type="submit" hidden></Button>
        </Form>
    )
}


