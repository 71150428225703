// export const RootUrl = "http://127.0.0.1:5000";
// export const ApiUrl = "http://127.0.0.1:5000/api/v1";

// export const RootUrl = "https://18.133.73.43:5000";
// export const ApiUrl = "https://18.133.73.43:5000/api/v1";

// export const RootUrl = "https://ec2-18-133-73-43.eu-west-2.compute.amazonaws.com:5000/";
// export const ApiUrl = "https://ec2-18-133-73-43.eu-west-2.compute.amazonaws.com:5000/api/v1"

export const RootUrl = "https://fasioninspectorlondon.com";
export const ApiUrl = "https://fasioninspectorlondon.com/api/v1";