import "bootstrap/dist/css/bootstrap.min.css";
// import "https://cdn.jsdelivr.net/npm/cloudinary-video-player/dist/cld-video-player.min.css";
import "../../css/styles.css";
import {ThemeCards} from "../../js/cards";
import {useLoaderData} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";


// export async function categoryLoader() {
//     const result = await getCategories();
//     const categories = result.data.categories;
//     return {categories};
// }


// export async function themeLoader({params}) {
//     // const {categorySlug} = useParams();
//     // const [category_id, category_name] = categorySlug.split("&");
//     if (params.category_id !== "all-careers") {
//         const themes = await getThemesByCategory(params.category_id);
//         return {
//             "category_name": params.category_name,
//             "themes": themes.data.themes
//         }
//     }
//     const themes = await getThemes();
//     return {
//         "category_name": "Our Subjects",
//         "themes": themes.data.themes
//     }
//
// }

// export async function programmeLoader({params}) {
//     const result = await getReleasedProgramme(params.theme_id);
//     const programme = result.data.programmes;
//     return {programme};
// }

export function ThemesList() {
    const info = useLoaderData();
    return (
        <>
            <div className="themesList-header">
                {info.category_name ? (
                    <h1>{info.category_name}</h1>
                ) : (
                    <h1>Our Subjects</h1>
                )}
            </div>
            <div className="themesList-body">
                <div className="search-bar"></div>
                <div className="themeList">
                    <Row className="themeCards justify-content-md-center" xs={1} md={4} xxl={6}>
                        {info.themes.length ? (
                            <>
                                {info.themes.map((theme) => (
                                    <ThemeCards thumbnail={theme.thumbnail} id={theme.id} name={theme.name}/>
                                ))}
                            </>

                        ) : (
                            <span>No Subject yet, stay tune</span>
                        )}
                    </Row>
                </div>
            </div>
        </>
    )

}


export function ProgrammeInfo() {
    const info = useLoaderData();
    // new CloudinaryVideo(info.programme.video).transcode(
    //     streamingProfile("auto:maxres_360p")
    // );
    // document.getElementById("player").source(info.programme.video);
    return (
        <>
            <div className="programmeInfo-header" style={{background: "url(" + info.programme.bg_image + ")"}}>
                <h1>{info.programme.name}</h1>
            </div>
            <div className="programmeInfo-body">
                <Row>
                    <Col md={{span: 6}}>
                        <div className="media">
                            <Stack gap={3}>
                                <Row>
                                    <Col className="video">
                                        <iframe
                                            src={`https://player.cloudinary.com/embed/?public_id=${info.programme.id}-video&cloud_name=dpvqilk0c`}
                                            // width="inherit"
                                            // height="inherit"
                                            style={{height: "100%", width: "100%"}}
                                            allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
                                            frameBorder="0"
                                        ></iframe>
                                        {/*<video id="player"*/}
                                        {/*       className="cld-video-player cld-fluid"*/}
                                        {/*       muted*/}
                                        {/*       controls*/}
                                        {/*></video>*/}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="picture1"
                                         md={{span: 8, offset: 4}}
                                         style={{background: "url(" + info.programme.pic1 + ")"}}
                                    >
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="picture2"
                                         md={{span: 8}}
                                         style={{background: "url(" + info.programme.pic2 + ")"}}
                                    ></Col>
                                </Row>
                            </Stack>
                        </div>
                    </Col>
                    <Col className="programme-intro" md={{span: 5}}>
                        <div className="overview">
                            <h2>Overview</h2>
                            <p>{info.programme.overview}</p>
                            <p>Dates: {info.programme.dates}</p>
                            <p>Length: {info.programme.length}</p>
                            <p>Location: {info.programme.location}</p>
                            <p>Accommodation: {info.programme.accommodation}</p>
                        </div>
                        <div className="price">
                            <span>price: </span>
                            <h3 style={{display: "inline"}}>£{info.programme.price}</h3>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className="description"></div>
                </Row>
            </div>
        </>
    )
}