import "../../css/styles.css"
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import {NavigationBar} from "../../js/navbar";
import {RootUrl, ApiUrl} from "../../js/url";
import {VisPassword} from "../../js/password";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import axios from "axios";


export function Register() {
    // eslint-disable-next-line no-restricted-globals
    // console.log(history.location);
    return (
        <>
            {/*<NavigationBar/>*/}
            <Container fluid className="mainContent">
                <RegisterForm/>
            </Container>
        </>
    );
}

function RegisterForm() {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        firstname: "",
        lastname: "",
        sex: "",
        number: "",
        confirmPass: ""
    });
    const [cookies, setCookies] = useCookies();

    var params = {};

    useEffect(() => {
        params = formData;
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handlePass = (e) => {
        const {name, value} = e.target;
        if(params.password !== value){
            return null;
        }
    }

    const handleRegisterSubmit = (e) => {
        e.preventDefault();
        // eslint-disable-next-line no-restricted-globals
        // // Do something with the form data, for example, make an API request
        axios.post(RootUrl + "/register", params,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                },
            }).then(response => {
            const res = response.data
            if (res.msg == "Register successful!") {
                const user = params.firstname + " " + params.lastname;
                setCookies("user", user);

                // eslint-disable-next-line no-restricted-globals
                history.go(-2);
            }
        }).catch(errors => {
            console.log(errors);
        });

    };

    return (
        <div className="register" onSubmit={handleRegisterSubmit}>
            <Stack gap={3}>
                <div className="register-header"></div>
                <div className="register-form">
                    <Form method="post" action="/register">
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="firstname">
                                <Row>
                                    <Form.Label column sm={4}>First Name</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            name="firstname"
                                            type="text"
                                            placeholder=""
                                            onChange={handleInputChange}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3" controlId="lastname">
                                <Row>
                                    <Form.Label column sm={4}>Last Name</Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            name="lastname"
                                            type="text"
                                            placeholder=""
                                            onChange={handleInputChange}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Row>
                        <fieldset>
                            <Form.Group as={Row} className="mb-3" controlId="sex">
                                <Form.Label column sm={2} as="legend">Sex</Form.Label>
                                {/*<Col sm={10}>*/}
                                {["radio"].map((type) => (
                                    // <div key={`inline-${type}`}>
                                    <Col sm={10}>
                                        <Form.Check
                                            // inline
                                            label="Male"
                                            name="sex"
                                            type={type}
                                            id="sex-1"
                                            value={1}
                                            onChange={handleInputChange}
                                        />
                                        <Form.Check
                                            // inline
                                            label="Female"
                                            name="sex"
                                            type={type}
                                            id="sex-2"
                                            value={2}
                                            onChange={handleInputChange}
                                        />
                                        <Form.Check
                                            // inline
                                            label="Prefer no to say"
                                            name="sex"
                                            type={type}
                                            id="sex-0"
                                            value={0}
                                            onChange={handleInputChange}
                                        />
                                        {/*</div>*/}
                                    </Col>
                                ))}
                                {/*</Col*/}
                            </Form.Group>
                        </fieldset>
                        <Form.Group as={Row} className="mb-3" controlId="registerEmail">
                            <Form.Label column sm={2}>Email</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder="example@email.com"
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="mobileNum">
                            <Form.Label column sm={2}>Mobile Number</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    name="number"
                                    type="text"
                                    placeholder="07*********"
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="registerPassword">
                            <Form.Label column sm={2}>Password</Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <VisPassword
                                        label="Password"
                                        name="password"
                                        num="0"
                                        onChange={handleInputChange}
                                    />
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="confirmPassword">
                            <Form.Label column sm={2}>Confirm Password</Form.Label>
                            <Col sm={10}>
                                <InputGroup>
                                    <VisPassword label="Confirm Password" num="1"/>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Col md={{span: 6, offset: 3}}>
                            <div className="d-grid gap-2">
                                <Button size="lg" variant="primary" type="submit">
                                    Sign Up
                                </Button>
                            </div>
                        </Col>
                    </Form>
                </div>
                <div className="register-footer">
                    {/*<Col md={{span: 6, offset: 3}}>*/}
                    {/*    <div className="d-grid gap-2">*/}
                    {/*        <Button size="lg" variant="primary" type="submit">*/}
                    {/*            Sign Up*/}
                    {/*        </Button>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </div>
            </Stack>
        </div>
    );
}