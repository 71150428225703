import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/styles.css";
import Button from "react-bootstrap/Button";
import {Outlet} from "react-router-dom";
import {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import {AddTheme} from "../programmes/programme_management";
import {ManageConfig} from "../config/basic_config";
import {ApiUrl} from "../../js/url";
import axios from "axios";

export function ConfigIndex() {
    const url = window.location.href;
    const location = url.split("/").pop();

    const [show, setShow] = useState(false);
    const handleOnSubmit = () => {
        document.getElementById("submit").click();
    }


    return (
        <>
            <Modal id="form-modal" className="form-modal" centered size="lg" show={show}>
                <AddNew path={location} onHide={() => setShow(false)}/>
                <Modal.Footer>
                    <Button id="close-modal" variant="secondary" onClick={() => setShow(false)}>Close</Button>
                    <Button variant="primary" type="submit" onClick={handleOnSubmit}>Save</Button>
                </Modal.Footer>
                <div id="modal-backdrop" className="fade modal-backdrop show" hidden>
                    <Spinner animation="border" variant="primary" />
                </div>
            </Modal>
            <div className="indexHeader">
                <Button variant="secondary" onClick={() => setShow(true)}>Add New</Button>
            </div>
            <Outlet></Outlet>
        </>
    )

}

function AddNew(props) {
    let header = "";
    let iframe = null;

    switch (props.path) {
        case "basic-config":
            header = "Add Config";
            return (
                <>
                    <Modal.Header closeButton onHide={props.onHide}>
                        <Modal.Title>
                            {header}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ManageConfig/>
                    </Modal.Body>
                </>
            );
        case "categories":
            header = "Add Category";
            break;
        case "programmes":
            header = "Add Theme";
            return (
                <>
                    <Modal.Header closeButton onHide={props.onHide}>
                        <Modal.Title>
                            {header}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddTheme/>
                    </Modal.Body>
                </>
            );
    }
}

