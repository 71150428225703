import "bootstrap/dist/css/bootstrap.min.css";
import "../css/styles.css";
import "../css/cards.css";
import {useState} from "react";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import axios from "axios";
import {RootUrl} from "./url";

export function IndexCards() {
    return (
        <>
            <Col>
                {/*<Card style={{width: '18rem'}}>*/}
                <Card>
                    <Card.Img className="indexCardImg" variant="top" src="cards/48b547493d.jpg"/>
                    <Card.Body>
                        <Card.Title>Card Title</Card.Title>
                        <Card.Text>
                            Some quick example text to build on the card title and make up the
                            bulk of the card's content.
                        </Card.Text>
                        <Button variant="primary">Go somewhere</Button>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
}

export function ThemeCards(props){
    return (
        <>
            <Col>
                <Card>
                    <Card.Img className="themeCardImg" variant="top" src={props.thumbnail}/>
                    <Card.Body>
                        <Button className="link-button theme-button" href={`/programmes/${props.id}`} variant="light">
                            <h3>{props.name}</h3>
                        </Button>
                    </Card.Body>
                </Card>
            </Col>
        </>
    )
}
