import './css/styles.css';
import {createRoot} from 'react-dom/client';
import * as React from 'react';
import Row from "react-bootstrap/Row";
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import {ControlledCarousel, IndexCards, NavigationBar} from "./js/navbar";
import ErrorPage from "./js/error_page";
import {auth} from "./js/auth";
import {Footer} from "./js/footer";
import {configLoader, programmeLoader, rootLoader, themesByCategoryLoader, themesLoader} from "./js/loader";
import {Login} from "./routes/account/login";
import {Register} from "./routes/account/register";
import {ProgrammeInfo, ThemesList} from "./routes/programmes/programme_index";
import {ConfigIndex} from "./routes/config/config_index";
import {ConfigTable} from "./routes/config/basic_config";
import {CategoriesTable} from "./routes/programmes/categories_management";
import {ProgrammeTable} from "./routes/programmes/programme_management";
import {Helmet} from "react-helmet";
import {useCookies} from "react-cookie";


const router = createBrowserRouter([
    {
        // path: "/",
        element: <Index/>,
        // element: <NavigationBar/>,
        errorElement: <ErrorPage/>,
        // loader:categoryLoader,
        loader: rootLoader,
        children: [
            {
                path: "/",
                element: <IndexBody/>
            },
            {
                path: "login",
                element: <Login/>
            },
            {
                path: "password-reset/forget-password"
            },
            {
                path: "register",
                element: <Register/>
            },
            {
                path: "cart",
            },
            {
                path: "programmes/all-careers",
                element: <ThemesList/>,
                loader: themesLoader
            },
            {
                path: "programmes/:category_id/:category_name",
                element: <ThemesList/>,
                loader: themesByCategoryLoader
            },
            {
                path: "programmes/:theme_id",
                // path:"programmes",
                element: <ProgrammeInfo/>,
                loader: programmeLoader
            },
            {
                path: "config",
                element: <ConfigIndex/>,
                loader: (async () => {
                    return await fetch(auth());
                }),
                errorElement: <ErrorPage/>,
                children: [
                    {
                        path: "basic-config",
                        element: <ConfigTable/>,
                        loader: configLoader
                    },
                    {
                        path: "categories",
                        element: <CategoriesTable/>,
                    },
                    {
                        path: "programmes",
                        element: <ProgrammeTable/>,
                        loader: themesLoader
                    }
                ]
            },
        ]
    },
]);


export default function Index() {
    // const loadAuth = useLoaderData();
    // console.log(loadAuth);
    // const user = document.cookie.split("; ")
    //     .find((row) => row.startsWith("user="))
    //     ?.split("=")[1].replace("%20", " ");
    // const role = document.cookie.split("; ")
    //     .find((row) => row.startsWith("role="))
    //     ?.split("=")[1];
    const cookieArray = () => {
        if (document.cookie !== "") {
            return document.cookie.split("; ");
        }
        return []
    }
    const [cookies, setCookies] = useCookies(cookieArray());

    let user, role, id = "";

    if (cookies !== {}) {
        user = cookies.user;
        role = cookies.role;
        id = cookies.id;
    }
    return (
        <div>
            <Helmet>
                <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>
            </Helmet>

            <NavigationBar user={user} role={role}/>
            <div className="mainContent">
                <Outlet></Outlet>
            </div>
            <Footer/>
        </div>
    );
}


function IndexBody() {
    return (
        <>
            {/*<div className="mainContent">*/}
            <Row>
                <ControlledCarousel/>
            </Row>
            <Row className="indexCards">
                <IndexCards/>
            </Row>
            {/*</div>*/}
        </>
    )
}


const root = createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <>
            <RouterProvider router={router}/>
        </>
    </React.StrictMode>
);


