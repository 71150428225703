import {useRouteError} from "react-router-dom";

export default function ErrorPage(){
    const error = useRouteError();
    console.error(error);

    if(error.status === 401){
        return (
            <div id="error-page">
                <h1>Unauthorized Account</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    Please&nbsp;
                    <a href="/login">login again</a>
                </p>
            </div>
        );
    }
    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}